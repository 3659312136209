// extracted by mini-css-extract-plugin
export var animalImage = "animals-module--animalImage--5ba86";
export var animalList = "animals-module--animalList--bebb0";
export var animalName = "animals-module--animalName--a2188";
export var animalType = "animals-module--animalType--ae72a";
export var card = "animals-module--card--9137d";
export var cardDetails = "animals-module--cardDetails--8d12e";
export var description = "animals-module--description--f8ca5";
export var imageWrapper = "animals-module--imageWrapper--73035";
export var pageTitle = "animals-module--pageTitle--37810";
export var viewDetailsIcon = "animals-module--viewDetailsIcon--52fcc";
export var viewDetailsLink = "animals-module--viewDetailsLink--41995";